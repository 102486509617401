._headerList{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

@media (max-width:767px) {
    ._inputForm{
        width: 100% !important;
        margin-bottom: 1rem !important;
    }
    ._btnAdd{
        width: 100% !important;
    }
}