:root {
  --ck-z-default: 100;
  --ck-z-panel: calc( var(--ck-z-default) + 999 );
}

.cke_notification {
  opacity: 0 !important;
}

.ck.ck-balloon-panel {
  z-index:9999 !important;
  left: 500px !important;
}

.cardFooter{
    justify-content: space-between;
    align-items: center;
  }
  .userProfile{
    display: flex;
    align-items: center;
    justify-self: center;
  }
  .cardImg{
    width: 100%;
  }
  ._fiara1{
    width: 100% !important;
    height: auto;
  }
  
  ._fiaraDetail{
    width: 100% !important;
    height: auto;
  }
  ._userName{
    margin-left: 5px;
  }
  .adress_{
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }


  .boxEq {
    display: flex;
    flex-wrap: wrap;
    flex: 0;
    justify-content: space-between;
  }
  .ville_{
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    text-transform: uppercase;
  }
  .cardFooter{
    display: flex;
  }
  .img-profile{
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .contentHeader{
    align-items: center;
  }
  
  .contentHeader .listHeader{
    justify-content: space-between;
  }
  .page-item{
    cursor: pointer;
  }
  
  .headerTitle{
    font-size: 16px;
    font-weight: 600;
    color: rgb(54, 54, 54);
  }
  .subTitleHeader{
    font-size: 14px;
    font-weight: 700;
    color: rgb(54, 54, 54);
  }
  
  ._homePage{
    margin-top: 6rem;
  }

  ._btnShop{
    background-color: rgb(224, 224, 224);
  }
  ._price{
    font-size: 0.8rem;
    font-weight: 600;
    margin: 0 !important;

  }
  .btnComment{
    background-color: rgb(224, 224, 224);
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;
  }
  .recent_{
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .recent_ h3 {
    font-weight: 600;
    font-size: 20px;
  }

  .imgBox_{
    display: flex;
    flex-wrap: wrap;
    flex: 0;
    justify-content: space-between;
  }

  .boxImg_ {
    display: flex;
    flex-direction: column !important;
  }

  .boxImg_ button {
    width: auto !important;
    border: none;
    
  }
  ._imgBox_ {
    display: flex;
    flex-wrap: wrap;
    flex: 0;
    justify-content: space-between;
  }
  ._imgS{
    width: 20%;
  }


  .react-datepicker-ignore-onclickoutside {
    padding: 0.6rem !important;
    border: 2px solid gray !important;
    border-radius: 5px !important;
    width: 50% !important;
  }

  .contenu {
    padding: 2rem;
  }

  .bg-info {
    background-color: #e7eced!important;
    border-radius: 5px !important;
  }

  .bg-info, .bg-info>a {
    color: gray !important;
  }

  .small-box .icon>i {
    font-size: 90px;
    position: absolute;
    right: 15px;
    top: 15px;
    color: #8080805c !important;
    transition: -webkit-transform .3s linear;
    transition: transform .3s linear;
    transition: transform .3s linear,-webkit-transform .3s linear;
  }

  .loading {
    text-align: center !important;
    justify-content: centre !important;
    align-items: center !important;
    margin: auto !important;
  }

  .noData {
    display: flex;
    justify-content: center;
  }

  .deleteBTN_{
    position: absolute;
    right: 0;

  }

  figure {
    border: thin #ebe9e9 solid;
    display: flex;
    flex-flow: column;
    padding: 5px;
    max-width: 220px;
    margin: auto;
    margin-bottom: 0.5rem;

  }

  figure img {
      max-width: 220px;
      max-height: 150px;
      margin: auto !important;
  }

  figure img._imgS_{
    width: 100%;
    cursor: pointer;
  }

  figure img._imgS {
    width: 100%;
    transition: transform .2s; /* Animation */
  }

  figure img._imgS:hover {
    transform: scale(1.9); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .modal-update {
    width: 50% !important;
  }

  figcaption {
      background-color: #222;
      color: #fff;
      font: italic smaller sans-serif;
      padding: 3px;
      text-align: center;
  }

  .table > tbody > tr > td {
    vertical-align: middle !important;
  }
  @media  (max-width: 436px){
    .listHeader{
      justify-content: flex-start;
    }
  }
  
  @media  (max-width: 767px){
    ._fiara1{
      width: 100% !important;
      height: auto;
    }
  }